/**
 * Fonction pour calculer la largeur d'un sous-arbre (nombre d'employés au total).
 * @param {string} employeeId - ID de l'employé racine du sous-arbre.
 * @param {Array} employees - Liste des employés avec leurs relations hiérarchiques.
 * @returns {number} width - Largeur du sous-arbre.
 */
export const calculateSubtreeWidth = (employeeId, employees) => {
  const children = employees.filter((e) => e.parentId === employeeId);
  if (children.length === 0) return 1; // Feuille = largeur de 1 unité
  return children.reduce(
    (width, child) => width + calculateSubtreeWidth(child.id, employees),
    0
  );
};