import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../../services/FirebaseConfig";
import './EmployeeFilterPerDepartmentService.css';

const EmployeeFilterPerServiceDepartment = ({ companyId, onFilterChange }) => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("all");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("all");

  // Charger les départements, services et localisations à partir des employés
  useEffect(() => {
    if (!companyId) return;

    const fetchFilters = async () => {
      const q = query(
        collection(db, "employees"),
        where("companyId", "==", companyId)
      );

      const querySnapshot = await getDocs(q);
      const employees = querySnapshot.docs.map((doc) => doc.data());

      // Extraire les départements uniques
      const uniqueDepartments = [
        ...new Set(employees.map((emp) => emp.department)),
      ].filter(Boolean);
      setDepartments(uniqueDepartments);

      // Extraire les services uniques
      const uniqueServices = [
        ...new Set(employees.map((emp) => emp.service)),
      ].filter(Boolean);
      setServices(uniqueServices);

      // Extraire les localisations uniques
      const uniqueLocations = [
        ...new Set(employees.map((emp) => emp.location)),
      ].filter(Boolean);
      setLocations(uniqueLocations);

      // Réinitialiser les sélections
      setSelectedService("all");
      setSelectedLocation("all");
      onFilterChange({ department: "all", service: "all", location: "all" });
    };

    fetchFilters();
  }, [companyId, onFilterChange]);

  // Charger les services et localisations pour le département sélectionné
  useEffect(() => {
    const fetchFilters = async () => {
      const q = query(
        collection(db, "employees"),
        where("companyId", "==", companyId)
      );

      const querySnapshot = await getDocs(q);
      const employees = querySnapshot.docs.map((doc) => doc.data());

      if (selectedDepartment === "all") {
        const allServices = [
          ...new Set(employees.map((emp) => emp.service)),
        ].filter(Boolean);
        setServices(allServices);

        const allLocations = [
          ...new Set(employees.map((emp) => emp.location)),
        ].filter(Boolean);
        setLocations(allLocations);

        onFilterChange({ department: "all", service: "all", location: "all" });
      } else {
        const filteredServices = [
          ...new Set(
            employees
              .filter((emp) => emp.department === selectedDepartment)
              .map((emp) => emp.service)
          ),
        ].filter(Boolean);
        setServices(filteredServices);

        const filteredLocations = [
          ...new Set(
            employees
              .filter((emp) => emp.department === selectedDepartment)
              .map((emp) => emp.location)
          ),
        ].filter(Boolean);
        setLocations(filteredLocations);

        setSelectedService("all");
        setSelectedLocation("all");
        onFilterChange({ department: selectedDepartment, service: "all", location: "all" });
      }
    };

    fetchFilters();
  }, [selectedDepartment, companyId, onFilterChange]);

  // Gérer le changement de filtre
  const handleDepartmentChange = (e) => {
    const department = e.target.value;
    setSelectedDepartment(department);
  };

  const handleServiceChange = (e) => {
    const service = e.target.value;
    setSelectedService(service);
    onFilterChange({ department: selectedDepartment, service, location: selectedLocation });
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocation(location);
    onFilterChange({ department: selectedDepartment, service: selectedService, location });
  };

  return (
    <div className="filter-container">
      <select value={selectedDepartment} onChange={handleDepartmentChange}>
        <option value="all">Tous les départements</option>
        {departments.map((dept, index) => (
          <option key={index} value={dept}>
            {dept}
          </option>
        ))}
      </select>
      <select value={selectedService} onChange={handleServiceChange}>
        <option value="all">Tous les services</option>
        {services.map((service, index) => (
          <option key={index} value={service}>
            {service}
          </option>
        ))}
      </select>
      <select value={selectedLocation} onChange={handleLocationChange}>
        <option value="all">Toutes les localisations</option>
        {locations.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EmployeeFilterPerServiceDepartment;