import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import LogoutButton from "../../ui/buttons/LogoutButton";
import { FaCog, FaUserCircle, FaChevronDown } from "react-icons/fa";
import "./UserInfo.css";
import profilePhoto from "../../../assets/images/profilepicture.webp";

function UserInfo() {
  const { user } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const displayName =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.email;

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div className="user-info">
      <div className="user-header" onClick={toggleDropdown}>
        <span className="user-name">{displayName}</span>
        {user.photoURL ? (
          <img
            src={user.photoURL}
            alt="Profile"
            className="profile-image"
          />
        ) : (
          <img 
            src={profilePhoto} 
            alt="Profile" 
            className="profile-photo" 
          />
        )}
        <FaChevronDown className={`dropdown-icon ${dropdownOpen ? "open" : ""}`} />
      </div>

      {dropdownOpen && (
        <div className="user-dropdown">
          <div className="dropdown-item">
            <FaUserCircle className="dropdown-icon" />
            <span>Mon profil</span>
          </div>
          <a href="/settings" className="dropdown-item">
            <FaCog className="dropdown-icon" />
            <span>Paramètres</span>
          </a>
          <div className="dropdown-item">
            <LogoutButton />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInfo;