import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <select
      className="language-selector"
      onChange={(e) => changeLanguage(e.target.value)}
      defaultValue={i18n.language}
    >
      <option value="en">English</option>
      <option value="zh">中文 (Chinese)</option>
      <option value="es">Español (Spanish)</option>
      <option value="hi">हिन्दी (Hindi)</option>
      <option value="ar">العربية (Arabic)</option>
      <option value="fr">Français (French)</option>
      <option value="ru">Русский (Russian)</option>
      <option value="pt">Português (Portuguese)</option>
      <option value="de">Deutsch (German)</option>
      <option value="ja">日本語 (Japanese)</option>
    </select>
  );
};

export default LanguageSelector;