import React, { useState } from 'react';
import styles from './EmployeeDataInput.module.css';

const EmployeeDataInput = ({
  type = '',       // Type de l'input (texte, email, mot de passe, fichier, etc.)
  label = '',          // Label pour l'input
  placeholder = '',    // Placeholder du champ
  value = '',          // Valeur initiale
  onChange = () => {}, // Fonction pour gérer les changements de valeur
  required = false,    // Si le champ est requis ou non
  className = '',
  disabled = false,
  maxLength = null,    // Longueur maximale
  minLength = null,    // Longueur minimale
  errorMessage = '',   // Message d'erreur en cas de validation
  validate = null,     // Fonction de validation personnalisée
  accept = null,        // Acceptation des types MIME pour les fichiers
}) => {
  const [error, setError] = useState('');

  const handleChange = (event) => {
    const newValue = event.target.value;

    // Validation personnalisée ou par défaut
    if (required && !newValue) {
      setError('Ce champ est requis.');
    } else if (validate) {
      const validationError = validate(newValue);
      setError(validationError ? errorMessage : '');
    } else if (type === 'email') {
      // Validation simple de l'e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setError(emailRegex.test(newValue) ? '' : 'Adresse e-mail invalide.');
    } else {
      setError(''); // Pas d'erreur
    }

    // Transmettre la valeur au parent
    onChange(newValue);
  };

  return (
    <div className={className}>
      <div className={`LabelError ${className}`}>
        {label && <label>{label}</label>}
        {error && <span className="error-message">{error}</span>}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        className={styles.EmployeeDataInput}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        accept={accept}
        aria-invalid={error ? "true" : "false"}
        style={{
          backgroundColor: disabled ? "#f0f0f0" : "white",
          cursor: disabled ? "not-allowed" : "text",
        }}
      />
    </div>
  );
};

export default EmployeeDataInput;
