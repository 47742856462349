/**
 * Fonction pour assigner les positions des employés dans l'organigramme.
 * @param {string} employeeId - ID de l'employé en cours de traitement.
 * @param {number} xOffset - Position horizontale de départ.
 * @param {number} yOffset - Position verticale de départ.
 * @param {Array} employees - Liste des employés avec leurs relations hiérarchiques.
 * @param {Object} levels - Niveaux hiérarchiques des employés { id: niveau }.
 * @param {Object} spacing - Espacement des niveaux et des frères/sœurs.
 * @param {Object} positions - Objet contenant les positions calculées { id: { x, y } }.
 * @returns {number} width - Largeur totale occupée par l'employé et ses enfants.
 */
export const assignPositions = (
  employeeId,
  xOffset,
  yOffset,
  employees,
  levels,
  spacing,
  positions
) => {
  const { levelSpacing, siblingSpacing, verticalSpacing } = spacing;
  const level = levels[employeeId];
  const yPosition = level * levelSpacing;
  const children = employees.filter((e) => e.parentId === employeeId);

  if (children.length === 0) {
    // Feuille : Position directe
    positions[employeeId] = { x: xOffset, y: yPosition };
    return siblingSpacing;
  }

  // Récursivement calculer les positions des enfants
  let childXOffset = xOffset;
  children.forEach((child) => {
    const childWidth = assignPositions(
      child.id,
      childXOffset,
      yPosition + verticalSpacing,
      employees,
      levels,
      spacing,
      positions
    );
    childXOffset += childWidth;
  });

  // Positionner le parent au centre de ses enfants
  const firstChild = children[0].id;
  const lastChild = children[children.length - 1].id;
  const parentX = (positions[firstChild].x + positions[lastChild].x) / 2;
  positions[employeeId] = { x: parentX, y: yPosition };

  return childXOffset - xOffset;
};