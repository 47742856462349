import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../services/FirebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import SaveUserData from "../../../hooks/SaveUserData";
import { useTranslation } from 'react-i18next';
import CloseButton from '../../ui/buttons/CloseButton';
import ButtonStyle1 from '../../ui/buttons/ButtonStyle1';
import UserDataInput from '../../ui/inputs/UserDataInput';
import LanguageSelector from '../../ui/selects/LanguageSelector';
import './SubscriptionPage.css';

const db = getFirestore();

function SubscriptionPage() {
  const navigate = useNavigate();
  const goToWelcomePage = () => {
    navigate('/');
  };
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState(''); // Nom de l'entreprise
  const [error, setError] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const handleSubscription = async (e) => {
    e.preventDefault();

    // Vérification des mots de passe
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    const TIMEOUT_DURATION = 10000;

    const withTimeout = (promise, timeoutMessage) => {
      return Promise.race([
        promise,
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error(timeoutMessage)), TIMEOUT_DURATION)
        ),
      ]);
    };

    try {
      // Étape 1 : Créer un utilisateur dans Firebase Auth
      setLoadingMessage("Création de l'utilisateur en cours...");
      const userCredential = await withTimeout(
        createUserWithEmailAndPassword(auth, email, password),
        "Le délai pour créer l'utilisateur est dépassé."
      );
      const user = userCredential.user;

      // Étape 3 : Vérifier ou créer la compagnie
      setLoadingMessage("Vérification ou création de l'entreprise...");
      const companiesRef = collection(db, 'companies');
      const q = query(companiesRef, where('name', '==', companyName));
      const companySnapshot = await withTimeout(
        getDocs(q),
        "Le délai pour vérifier les entreprises est dépassé."
      );

      let companyDocId = '';
      if (!companySnapshot.empty) {
        companyDocId = companySnapshot.docs[0].id;
      } else {
        // Si la compagnie n'existe pas, la créer
        const companyDoc = await withTimeout(
          addDoc(companiesRef, { 
            companyName: companyName, 
            siret: '',
            billingAddress: '',
            additionalInfo: '',
            isVerified: false,
          }),
          "Le délai pour créer l'entreprise est dépassé."
        );
        companyDocId = companyDoc.id;
      }

      // Étape 4 : Sauvegarder les données utilisateur dans Firestore
      setLoadingMessage("Sauvegarde des données utilisateur...");
      await withTimeout(
        SaveUserData(user.uid, { firstName, lastName, email, companyId: companyDocId }),
        "Le délai pour sauvegarder les données utilisateur est dépassé."
      );

      // Étape 5 : Ajouter l'utilisateur à la collection employees
      setLoadingMessage("Ajout d'un nouvel employé...");
      const employeesRef = collection(db, 'employees');
      await withTimeout(
        addDoc(employeesRef, {
          firstName,
          lastName,
          email,
          companyId: companyDocId, // Associer l'ID de la compagnie à l'employé
          department: '',
          service: '',
          jobTitle: '',
          location: '',
          parentId: '',
          stepParentId: '',
          language: i18n.language,
          isEditor: true,
          isUserVerified: false
        }),
        "Le délai pour ajouter un employé est dépassé."
      );

      // Étape 6 : Connexion et redirection vers orgchartPage
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError("Erreur d'inscription : " + err.message);
    } finally {
      setLoadingMessage('');
    }
  };

  return (
    <div className="subscription-wrapper">
      <LanguageSelector />
      <form className="subscription-form" onSubmit={handleSubscription}>
        <CloseButton onClose={goToWelcomePage} />
        <h2>{t('sign_in_title')}</h2>
        {error && <p className="error-message">{error}</p>}
        {loadingMessage && <p className="loading-message">{loadingMessage}</p>}
        <UserDataInput
          type="text"
          label="first_name"
          placeholder="enter_first_name"
          value={firstName}
          onChange={(value) => setFirstName(value)}
          required
        />
        <UserDataInput
          type="text"
          label="last_name"
          placeholder="enter_last_name"
          value={lastName}
          onChange={(value) => setLastName(value)}
          required
        />
        <UserDataInput
          type="email"
          label="email"
          placeholder="enter_email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
        />
        <UserDataInput
          type="text"
          label="company_name"
          placeholder="enter_company_name"
          value={companyName}
          onChange={(value) => setCompanyName(value)}
          required
        />
        <UserDataInput
          type="password"
          label="password"
          placeholder="enter_password"
          value={password}
          onChange={(value) => setPassword(value)}
          required
        />
        <UserDataInput
          type="password"
          label="confirm_password"
          placeholder="enter_password"
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
          required
        />
        <ButtonStyle1 type="submit" label="sign_in_button" />
      </form>
    </div>
  );
}

export default SubscriptionPage;