import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './WelcomePage.css';
import LanguageSelector from '../../ui/selects/LanguageSelector';

const WelcomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="welcome-wrapper">
      <LanguageSelector />
      <div className="welcome-wrapper-inside">
        <h1 className="title">{t('welcome_title')}</h1>
        <button className="big-button" onClick={goToLogin}>
          {t('login')}
        </button>
        <p>{t('visit_website')}</p>
        <a href="https://www.hiveteamflow.com" className="link-return-home">
          https://www.hiveteamflow.com
        </a>
      </div>
    </div>
  );
};

export default WelcomePage;