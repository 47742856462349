import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../services/FirebaseConfig";
import { getAuth } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import UserDataInput from '../../ui/inputs/UserDataInput';
import ButtonStyle1 from '../../ui/buttons/ButtonStyle1';
import "./SettingsPage.css";

function SettingsPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [siret, setSiret] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isVerified, setIsVerified] = useState("");
  const [message, setMessage] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser) {
        try {
          const q = query(
            collection(db, "employees"),
            where("email", "==", currentUser.email)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const employeeDoc = querySnapshot.docs[0];
            const employeeData = employeeDoc.data();
            setEmployeeId(employeeDoc.id);
            setFirstName(employeeData.firstName || "");
            setLastName(employeeData.lastName || "");
            setEmail(employeeData.email || "");
            setSelectedLanguage(employeeData.language || "en"); 
            setCompanyId(employeeData.companyId || "");

            if (employeeData.companyId) {
              const companyRef = doc(db, "companies", employeeData.companyId);
              const companySnapshot = await getDoc(companyRef);
              if (companySnapshot.exists()) {
                const companyData = companySnapshot.data();
                setCompanyName(companyData.companyName || "");
                setSiret(companyData.siret || "");
                setBillingAddress(companyData.billingAddress || "");
                setAdditionalInfo(companyData.additionalInfo || "");
                setIsVerified(companyData.isVerified);
              }
            }
          } else {
            console.error("Aucun employé trouvé pour cet utilisateur.");
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données utilisateur :",
            error
          );
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSaveProfile = async (e) => {
    e.preventDefault();

    if (!employeeId) {
      setMessage("Impossible de sauvegarder le profil : aucun employé trouvé.");
      return;
    }

    try {
      const employeeRef = doc(db, "employees", employeeId);
      await updateDoc(employeeRef, {
        firstName,
        lastName,
        email,
        language: selectedLanguage,
      });
      changeLanguage(selectedLanguage);
      setMessage("Profil mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      setMessage("Erreur lors de la mise à jour du profil.");
    }
  };

  // Mettre à jour les informations de l'entreprise
  const handleSaveCompany = async (e) => {
    e.preventDefault();

    if (!companyId) {
      setMessage("Impossible de sauvegarder les données de l'entreprise.");
      return;
    }

    try {
      const companyRef = doc(db, "companies", companyId);
      await updateDoc(companyRef, {
        companyName,
        siret,
        billingAddress,
        additionalInfo,
        isVerified,
      });
      setMessage("Informations de l'entreprise mises à jour avec succès.");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des informations de l'entreprise :",
        error
      );
      setMessage("Erreur lors de la mise à jour des informations de l'entreprise.");
    }
  };

  // Changer le mot de passe
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      try {
        await currentUser.updatePassword(password);
        setMessage("Mot de passe mis à jour avec succès.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour du mot de passe :", error);
        setMessage("Erreur lors de la mise à jour du mot de passe.");
      }
    } else {
      setMessage("Utilisateur non authentifié.");
    }
  };


  return (
    <div className="app-structure-page-with-header">
      <Header />
      <div className="app-page-intern">
        <h1>{t('profil_parameters')}</h1>
        {message && <div className="message">{message}</div>}

        {/* Formulaire des informations personnelles */}
        <form className="profile-form" onSubmit={handleSaveProfile}>
          <h2>{t('personal_informations')}</h2>
          <UserDataInput
            type="text"
            label="first_name"
            placeholder="enter_first_name"
            value={firstName}
            onChange={(value) => setFirstName(value)}
            required
          />
          <UserDataInput
            type="text"
            label="last_name"
            placeholder="enter_last_name"
            value={lastName}
            onChange={(value) => setLastName(value)}
            required
          />
          <UserDataInput
            type="email"
            label="email"
            placeholder="enter_email"
            value={email}
            onChange={(value) => setEmail(value)}
            required
          />
          <select
            onChange={(e) => setSelectedLanguage(e.target.value)}
            value={selectedLanguage}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
          </select>
          <ButtonStyle1 type="submit" label="save" />
        </form>

        {/* Formulaire des informations de l'entreprise */}
        <form className="company-form" onSubmit={handleSaveCompany}>
          <h2>Informations de l'entreprise</h2>
          <label>
            Nom de l'entreprise
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </label>
          <label>
            Numéro de SIRET
            <input
              type="text"
              value={siret}
              onChange={(e) => setSiret(e.target.value)}
            />
          </label>
          <label>
            Adresse de facturation
            <input
              type="text"
              value={billingAddress}
              onChange={(e) => setBillingAddress(e.target.value)}
            />
          </label>
          <label>
            Informations supplémentaires
            <textarea
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </label>
          <ButtonStyle1 type="submit" label="Save_and_check"/>
        </form>

        {/* Formulaire pour changer le mot de passe */}
        <form className="password-form" onSubmit={handleChangePassword}>
          <h2>Changer le mot de passe</h2>
          <label>
            Nouveau mot de passe
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label>
            Confirmer le mot de passe
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </label>
          <ButtonStyle1 type="submit" label="update_password"/>
        </form>
      </div>
    </div>
  );
}

export default SettingsPage;