/**
 * Fonction pour calculer les niveaux hiérarchiques des employés.
 * @param {Array} employees - Liste des employés avec leurs relations hiérarchiques.
 * @returns {Object} levels - Un objet contenant les niveaux { id: niveau }.
 */
export const getLevels = (employees) => {
  const levels = {};
  const assignLevel = (employeeId, level = 0) => {
    levels[employeeId] = level;
    const children = employees.filter((e) => e.parentId === employeeId);
    children.forEach((child) => assignLevel(child.id, level + 1));
  };

  // Trouver les racines (employés sans parent)
  const roots = employees.filter(
    (e) => !e.parentId || !employees.some((parent) => parent.id === e.parentId)
  );
  roots.forEach((root) => assignLevel(root.id));
  return levels;
};