/* src/components/pages/LoginPage/LoginPage.js */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../../services/FirebaseConfig';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../ui/buttons/CloseButton';
import ButtonStyle1 from '../../ui/buttons/ButtonStyle1';
import UserDataInput from '../../ui/inputs/UserDataInput';
import LanguageSelector from '../../ui/selects/LanguageSelector';
import { FaMicrosoft } from "react-icons/fa6";
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const goToWelcomePage = () => {
    navigate('/'); // Redirect to the welcome page
  };
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Redirect to the main page after login
    } catch (err) {
      setError("Erreur d'authentification : " + err.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/orgchart'); // Redirect to the main page after login
    } catch (err) {
      setError("Erreur d'authentification : " + err.message);
    }
  };

  return (
    <div className="login-wrapper">
      <LanguageSelector />
      <form className="login-form" onSubmit={handleLogin}>
        <CloseButton onClose={goToWelcomePage}/>
        <h2>{t('login')}</h2>
        {error && <p className="error-message">{error}</p>}
        <UserDataInput
          type="email"
          label="email"
          placeholder="enter_email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="password"
          label="password"
          placeholder="enter_password"
          value={password}
          onChange={(value) => setPassword(value)}
          required
          className="UserDataInput"
        />
        <ButtonStyle1 type="submit" label="login" />
        <ButtonStyle1 type="submit" label="login_microsoft" onClick={handleGoogleLogin}>
          <FaMicrosoft size={40} color="blue" />
        </ButtonStyle1>
      </form>
      <div className="link-container">
        <Link to="/subscribe">{t('create_account')}</Link>
        <Link to="/forgotpassword">{t('forgot_password')}</Link>
      </div>
    </div>
  );
};
export default LoginPage;