import React, { useState, useEffect } from "react";
import EmployeeTable from "../../tabs/EmployeeTable";
import Header from "../../layouts/Header";
import EmployeeForm from "../../ui/forms/EmployeeForm";
import { db } from "../../../services/FirebaseConfig";
import EmployeeFilterPerServiceDepartment from "../../ui/filters/EmployeeFilterPerServiceDepartment";
import { useTranslation } from 'react-i18next';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import './EmployeesListPage.css';

const EmployeesListPage  = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [filter, setFilter] = useState({ department: "all", service: "all" });
  const [isEditMode, setEditMode] = useState(true);
  const { t, i18n } = useTranslation();

  // Lier l'utilisateur connecté à son employé et récupérer le companyId
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Rechercher l'employé correspondant à l'email de l'utilisateur
          const q = query(
            collection(db, "employees"),
            where("email", "==", currentUser.email) // Filtrer par email
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const employeeData = querySnapshot.docs[0].data();
            setCompanyId(employeeData.companyId);
            i18n.changeLanguage(employeeData.language || "en");
          } else {
            console.error("Aucun employé trouvé pour cet utilisateur.");
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du companyId :", error);
        }
      } else {
        setCompanyId(null);
      }
    });

    return () => unsubscribe();
  }, [i18n]);

  // Récupérer les employés liés à l'ID de l'entreprise
  useEffect(() => {
    if (!companyId) return;

    const q = query(
      collection(db, "employees"),
      where("companyId", "==", companyId) // Filtrer par companyId
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEmployees(employeeData);
    });

    return () => unsubscribe();
  }, [companyId]);

  // Ajouter un employé dans Firebase
  const addEmployee = async (employee) => {
    try {
      const newEmployee = {
        ...employee,
        companyId, // Associer l'employé à l'ID de l'entreprise
      };
      await addDoc(collection(db, "employees"), newEmployee);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'employé :", error);
    }
  };

  //Afficher les donnees dans le form de l'employé selectionné
  useEffect(() => {
    if (!selectedEmployeeId) {
      setSelectedEmployee(null);
      return;
    }

    const employee = employees.find((e) => e.id === selectedEmployeeId);
    setSelectedEmployee(employee || null);
  }, [selectedEmployeeId, employees]);

  // Modifier un employé dans Firebase
  const updateEmployee = async (updatedEmployee) => {
    try {
      const employeeRef = doc(db, "employees", updatedEmployee.id);
      await updateDoc(employeeRef, updatedEmployee);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'employé :", error);
    }
  };

  // Supprimer un employé de Firebase
  const deleteEmployee = async () => {
    if (!selectedEmployeeId) return;

    try {
      const employeeRef = doc(db, "employees", selectedEmployeeId);
      await deleteDoc(employeeRef);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'employé :", error);
    }
  };
  
  return (
    <div className="app-structure-page-with-header">
      <Header />
      <div className="app-page-intern">
        <h1>{t('employeeTab_title')}</h1>
        <div className="filter-container">
          <EmployeeFilterPerServiceDepartment companyId={companyId} onFilterChange={setFilter} />
        </div>
        <div className="employeesList-container">
          <EmployeeTable
            employees={employees}
            selectedEmployeeId={selectedEmployeeId}
            setSelectedEmployeeId={setSelectedEmployeeId}
            companyId={companyId}
            isEditMode={isEditMode}
            setEditMode={setEditMode}
            setDelete={deleteEmployee}
            filter={filter}
          />
        </div>
        <div className="form-container">
          <EmployeeForm
            addEmployee={addEmployee}
            updateEmployee={updateEmployee}
            deleteEmployee={deleteEmployee}
            employees={employees}
            selectedEmployeeId={selectedEmployeeId}
            selectedEmployee={selectedEmployee}
            isEditMode={isEditMode}
            setEditMode={setEditMode}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeesListPage;
