import React, { useRef, useEffect, useState } from "react";
import ReactFlow, { Controls } from "reactflow";
import HexagonalNode from "../HexagonalNode";
import { calculatePositions } from "../../../utils/orgchartPositionCalculator/orgchartPositionCalculator";
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal"; // Import du modale
import "reactflow/dist/style.css";

const OrgChart = ({
  employees,
  selectedEmployeeId,
  setSelectedEmployeeId,
  companyId,
  isEditMode,
  setEditMode,
  setDelete,
  filter,
}) => {
  const reactFlowWrapper = useRef(null);
  const reactFlowInstance = useRef(null);

  const [activeIcon, setActiveIcon] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  // Appliquer les filtres sur les employés
  const filteredEmployees = employees.filter((emp) => {
    if (emp.companyId !== companyId) return false;
    if (filter.department !== "all" && emp.department !== filter.department)
      return false;
    if (filter.service !== "all" && emp.service !== filter.service) return false;
    if (filter.location !== "all" && emp.location !== filter.location) return false;
    return true;
  });

  // Fonction appelée lorsqu'un utilisateur confirme la suppression
  const handleConfirmDelete = () => {
    setDeleteConfirmationVisible(false); // Fermer la fenêtre
    setDelete(true); // Exécuter la suppression
  };

  // Fonction pour annuler la suppression
  const handleCancelDelete = () => {
    setDeleteConfirmationVisible(false);
  };

  const positions = calculatePositions(filteredEmployees);

  const nodes = filteredEmployees.map((emp) => ({
    id: emp.id,
    position: positions[emp.id],
    type: "hexagonal",
    data: {
      label: (
        <div style={{ textAlign: "center" }}>
          <div>
            <strong>{emp.firstName}</strong> <br />
            <small>{emp.lastName}</small>
          </div>
        </div>
      ),
    },
    sourcePosition: "bottom",
    targetPosition: "top",
  }));

  const edges = filteredEmployees
    .filter((emp) => emp.parentId)
    .map((emp) => ({
      id: `e${emp.parentId}-${emp.id}`,
      source: emp.parentId,
      target: emp.id,
      animated: true,
      style: { stroke: "black", strokeWidth: 1 },
    }));

  const nodeTypes = {
    hexagonal: (props) => (
      <HexagonalNode
        {...props}
        isSelected={selectedNode?.id === props.id}
        setIsSelected={(isSelected) => {
          if (isSelected) {
            setSelectedNode(props);
            setSelectedEmployeeId(props.id);
            setEditMode(false);
          } else {
            setSelectedNode(null);
            setSelectedEmployeeId(null);
            setEditMode(true);
          }
        }}
        onEdit={() => setEditMode(true)}
        onDelete={() => setDeleteConfirmationVisible(true)} // Affiche la fenêtre
        activeIcon={activeIcon}
        setActiveIcon={setActiveIcon}
        isEditMode={isEditMode}
        isDeleteConfirmationVisible={isDeleteConfirmationVisible}
      />
    ),
  };

  useEffect(() => {
    if (reactFlowInstance.current) {
      reactFlowInstance.current.fitView({ duration: 800, padding: 0.1 });
    }
  }, [nodes, edges]);

  return (
    <div
      ref={reactFlowWrapper}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        onInit={(instance) => (reactFlowInstance.current = instance)}
        panOnScroll={false}
        zoomOnScroll={true}
        minZoom={0.2}
        maxZoom={3}
      >
        <Controls showInteractive={false} />
      </ReactFlow>

      {/* Fenêtre de confirmation */}
      {isDeleteConfirmationVisible && (
        <DeleteConfirmationModal
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default OrgChart;