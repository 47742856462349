import React, { useState, useEffect } from "react";
import EmployeeDataInput from '../../inputs/EmployeeDataInput'
import "./EmployeeForm.css";

const EmployeeForm = ({
  addEmployee,
  updateEmployee,
  deleteEmployee,
  employees,
  selectedEmployeeId,
  selectedEmployee,
  isEditMode,
  setEditMode,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [service, setService] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [parentId, setParentId] = useState("");
  const [stepParentId, setStepParentId] = useState("");

  // Pré-remplir les champs lors de la sélection d'un employé
  useEffect(() => {
    if (selectedEmployee) {
      setFirstName(selectedEmployee.firstName);
      setLastName(selectedEmployee.lastName);
      setEmail(selectedEmployee.email);
      setDepartment(selectedEmployee.department);
      setService(selectedEmployee.service);
      setJobTitle(selectedEmployee.jobTitle);
      setLocation(selectedEmployee.location);
      setParentId(selectedEmployee.parentId || "");
      setStepParentId(selectedEmployee.stepParentId || "");
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setDepartment("");
      setService("");
      setJobTitle("");
      setLocation("");
      setParentId("");
      setStepParentId("");
    }
  }, [selectedEmployee]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newEmployee = {
      firstName,
      lastName,
      email,
      department: department.trim() || "Not Affected",
      service: service.trim() || "Not Affected",
      jobTitle,
      location: location.trim() || "Not Affected",
      parentId,
      stepParentId
    };

    addEmployee(newEmployee);
    resetForm();
  };

  const handleUpdate = () => {
    if (!selectedEmployeeId) return;

    const updatedEmployee = {
      id: selectedEmployeeId,
      firstName,
      lastName,
      email,
      department,
      service,
      jobTitle,
      location,
      parentId,
      stepParentId
    };

    updateEmployee(updatedEmployee);
    setEditMode(false)
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setDepartment("");
    setService("");
    setJobTitle("");
    setLocation("");
    setParentId("");
    setStepParentId("");
  };

  return (
    <form onSubmit={handleSubmit} className="employee-form">
      <h2>Gérer les employés</h2>
      <EmployeeDataInput
        type="text"
        label="First Name"
        placeholder="First Name"
        value={firstName}
        onChange={(value) => setFirstName(value)}
        required={true}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />        
      <EmployeeDataInput
        type="text"
        label="Last Name"
        placeholder="Last Name"
         value={lastName}
        onChange={(value) => setLastName(value)}
         required={false}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <EmployeeDataInput
        type="email"
        label="Email"
        placeholder="Email"
         value={email}
        onChange={(value) => setEmail(value)}
        required={true}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <EmployeeDataInput
        type="text"
        label="Department"
        placeholder="Department"
        value={department}
        onChange={(value) => setDepartment(value)}
        required={false}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <EmployeeDataInput
        type="text"
        label="Service"
        placeholder="Service"
        value={service}
        onChange={(value) => setService(value)}
        required={false}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <EmployeeDataInput
        type="text"
        label="Job Title"
        placeholder="Job Title"
        value={jobTitle}
        onChange={(value) => setJobTitle(value)}
        required={false}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <EmployeeDataInput
        type="text"
        label="Localisation"
        placeholder="Localisation"
        value={location}
        onChange={(value) => setLocation(value)}
        required={false}
        className="EmployeeDataInput"
        disabled={!isEditMode}
      />
      <div className="form-group">
        <label>Rapporte à</label>
        <select value={parentId} onChange={(e) => setParentId(e.target.value)}>
          <option value="">Aucune personne</option>
          {employees
            .filter((emp) => emp.id !== selectedEmployeeId)
            .map((emp) => (
          <option key={emp.id} value={emp.id}>
              {emp.firstName} {emp.lastName}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Géré aussi par</label>
        <select value={stepParentId} onChange={(e) => setStepParentId(e.target.value)}>
          <option value="">Aucune personne</option>
          {employees
            .filter((emp) => emp.id !== selectedEmployeeId)
            .map((emp) => (
          <option key={emp.id} value={emp.id}>
              {emp.firstName} {emp.lastName}</option>
          ))}
        </select>
      </div>
      <div className="form-actions">
        <button 
          type="submit" 
          className="add-button" 
          onClick={handleSubmit}
          disabled={!!selectedEmployeeId}
          style={{ display: !selectedEmployeeId ? "block" : "none" }}
        >
          Ajouter
        </button>
        <button
          type="button"
          className="update-button"
          onClick={handleUpdate}
          disabled={!selectedEmployeeId}
          style={{ display: !!selectedEmployeeId & isEditMode ? "block" : "none" }}
        >
          Modifier
        </button>
      </div>
    </form>
  );
};

export default EmployeeForm;
