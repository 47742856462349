import React from "react";

const EmployeeTable = ({
  employees,
  companyId,
  filter,
}) => {
  // Appliquer les filtres sur les employés
  const filteredEmployees = employees.filter((emp) => {
    if (emp.companyId !== companyId) return false;
    if (filter.department !== "all" && emp.department !== filter.department)
      return false;
    if (filter.service !== "all" && emp.service !== filter.service) return false;
    if (filter.location !== "all" && emp.location !== filter.location) return false;
    return true;
  });

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Prénom</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Nom</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Email</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Poste</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Langue</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Localisation</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Service</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Responsable (ID)</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Responsable Alternatif (ID)</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Éditeur</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Utilisateur Vérifié</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.map((emp) => (
            <tr key={emp.id}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.firstName || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.lastName || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.email || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.jobTitle || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.language || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.location || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.service || "Non défini"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.parentId || "Aucun"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.stepParentId || "Aucun"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.isEditor ? "Oui" : "Non"}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{emp.isUserVerified ? "Oui" : "Non"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;