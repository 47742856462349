// src/components/ui/buttons/ButtonStyle1/ButtonStyle1.js
import React from 'react';
import styles from './ButtonStyle1.module.css';
import { useTranslation } from 'react-i18next';

function ButtonStyle1 ({label, onClick}) {
  
  const { t } = useTranslation();
  return (
    <button 
      className={styles.ButtonStyle1} 
      onClick={onClick}
    >
      {t(label)}
    </button>
  );
}
export default ButtonStyle1;