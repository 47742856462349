import React from "react";
import { Handle } from "reactflow";

const HexagonalNode = ({ data, isSelected, setIsSelected, onEdit, onDelete, activeIcon, setActiveIcon, isEditMode, isDeleteConfirmationVisible }) => {

  const hexagonPath =
    "M 50,0 " +
    "L 100,25 " +
    "L 100,75 " +
    "L 50,100 " +
    "L 0,75 " +
    "L 0,25 " +
    "Z";

  const handleClick = (event) => {
    event.stopPropagation();
    setIsSelected(!isSelected);
    setActiveIcon(null);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    setActiveIcon("edit");
    onEdit(data.id);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setActiveIcon("delete");
    onDelete(data.id);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        width: "100px",
        height: "100px",
        position: "relative",
        cursor: "pointer",
        backgroundColor: isSelected ? "#e6f7ff" : "#fff",
        clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
        WebkitClipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
      }}
    >
      <svg width="100" height="100" style={{ display: "block" }}>
        <path
          d={hexagonPath}
          fill={isSelected ? "#f0f8ff" : "#fff"}
          stroke={isSelected ? "#007bff" : "#ddd"}
          strokeWidth="2"
        />
        <foreignObject x="10" y="10" width="80" height="80">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: isSelected ? "#007bff" : "#333",
              }}
            >
              <strong>{data.label}</strong>
            </div>
          </div>
        </foreignObject>
      </svg>
      {isSelected && (
        <div
          style={{
            position: "absolute",
            bottom: "0%",
            right: "0px",
            transform: "translateY(-30%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <button
            onClick={handleEditClick}
            style={{
              background: activeIcon === "edit" & isEditMode ? "#007bff" : "transparent", // Icône "enfoncée" si active
              color: activeIcon === "edit" ? "#fff" : "#000",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "10px",
              padding: "5px",
            }}
          >
            ✏️
          </button>
          <button
            onClick={handleDeleteClick}
            style={{
              background: activeIcon === "delete" & isDeleteConfirmationVisible ? "#dc3545" : "transparent", // Icône "enfoncée" si active
              color: activeIcon === "delete" ? "#fff" : "#000",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "10px",
              padding: "5px",
            }}
          >
            🗑️
          </button>
        </div>
      )}
      <Handle type="source" position="bottom" style={{ background: "#555" }} />
      <Handle type="target" position="top" style={{ background: "#555" }} />
    </div>
  );
};

export default HexagonalNode;
