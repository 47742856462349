import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import { db } from "../../../services/FirebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import employeeslist from "../../../assets/images/employeeslist.webp";
import orgchart from "../../../assets/images/orgchart.webp";
import './DashBoardPage.css';

const DashBoardPage = () => {
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const navigate = useNavigate();

  // Récupérer les données de l'employé connecté via Firebase
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Filtrer par l'email de l'utilisateur connecté
          const q = query(
            collection(db, "employees"),
            where("email", "==", currentUser.email)
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const employeeData = querySnapshot.docs[0].data();
            setCurrentEmployee(employeeData); // Stocker les données de l'employé connecté
          } else {
            console.error("Aucun employé trouvé pour cet utilisateur.");
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données de l'employé :", error);
        }
      } else {
        setCurrentEmployee(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="app-structure-page-with-header">
      <Header />
      <div className="app-page-intern">
        <h1>Dashboard</h1>
        <p>
          Bonjour {currentEmployee ? currentEmployee.firstName : "Utilisateur"}
        </p>
        <p>Voici les dernières actualités de votre organisation :</p>
        <p>Actualités !!!!!</p>
        <p>Menus :</p>

        <div className="menu-container">
          <div className="menu-item" onClick={() => navigate("/orgchart")}>
            <img src={orgchart} alt="Organigramme" />
            <p>Organigramme</p>
          </div>
          <div className="menu-item" onClick={() => navigate("/employeeslist")}>
            <img src={employeeslist} alt="Liste employés" />
            <p>Liste Employés</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardPage;