// src/components/ui/buttons/LogoutButton/LogoutButton.js
import React from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import styles from './LogoutButton.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoutButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const handleLogout = () => {
        logout();
        navigate("/");
    };
   
    return (
        <button onClick={handleLogout} className={styles.logoutButton}>
            {t('logout')}
        </button>
    );
};

export default LogoutButton;
